import React from "react";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import Modal from "../Components/Helper/Modal";
import YourListForm from "../Components/YourList/YourListForm";
import ApiConfig from "../config.json";

const userId = localStorage.getItem("user");
const sourceURL = `${ApiConfig.api_url}/Series/personal/`;

export default function YourList() {
  const [series, setSeries] = React.useState([]);
  function getData() {
    axios
      .get(`${sourceURL}${userId}`)
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }
  React.useEffect(() => {
    getData();
  }, []);

  if (!series[0])
    return (
      <div className="yourList">
        <h1 className="yourListText">
          Jelenleg ez a lista üres, még nem vettél fel egy sorozatot sem a
          kedvenceid közé!
        </h1>
      </div>
    );
  return (
    <React.Fragment>
      <div className="mainSeries">
        <div className="col-12 f-32 c-5  m-4 mtitle fw-400 pt-3">
          <div>
            <img src="/art/heart.svg" alt="" width="30" className="me-2"></img>
            <h2 className="d-inline-block">Kedvenc sorozataid listája</h2>
          </div>
        </div>
        <div className="col-12 pb-2">
          <div className="row my-4 mx-2 justify-content-start text-white">
            <div className="col-12 f-16 c-5 mt-4 mtitle fw-400"></div>
            {series.map((serieses) => (
              <React.Fragment>
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4 hirbox">
                  <a href="#" className="d-block">
                    <div className="row g-0 align-items-center">
                      <div className="col-auto pe-2 pict">
                        <img
                          src={ApiConfig.media_url + serieses.image_vertical}
                          onError={(e) => {
                            e.target.src = "/art/logo.png";
                            e.target.style = "object-fit: contain";
                          }}
                          alt="..."
                        ></img>
                      </div>
                      <div className="col">
                        <div className="f-20">{serieses.name}</div>
                        <div className="f-20 text-uppercase c-9">
                          Évadok száma: {serieses.seasons}
                        </div>
                        <div className="row g-0 align-items-center mt-3">
                          <div className="col-auto me-2 mb-2">
                            <Modal id={serieses.id} component={YourListForm} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </React.Fragment>
            ))}
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
