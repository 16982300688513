import React, { useState } from "react";
import PropTypes from "prop-types";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import Swal from "sweetalert2";
import ApiConfig from "../config.json";
import LoginModal from "../Components/Helper/LoginModal"
import LoginForm from "../Components/Login/LoginForm";

async function loginUser(credentials) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .post(
      `${ApiConfig.api_url}/token/pair`,
      JSON.stringify(credentials),
      config
    )
    .then((response) => {
      // access token: api lekeresekhez a bearer utan - lejar 5 perc alatt
      localStorage.setItem("access", response.data.access);
      // refresh token: amikor lejar az access token, ezzel lehet uj access tokent kerni az api-tol
      localStorage.setItem("refresh", response.data.refresh);
      localStorage.setItem("user", response.data.user.id);
      // mindkettot szukseges letarolni
      if (response.status === 200) window.location.href = "/";
      return response.data.access;
    }).catch(function (error) {
      Swal.fire({
        icon: "error",
        title: "Hiba!",
        text: "Helytelen felhasználónév és/vagy jelszó! Próbálja újra!",
      });
      return "";
    });
}
export default function Login({ setToken }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = loginUser({
      username,
      password,
    });
    setToken(localStorage.getItem("access"));
  };
  return (
    <>
      <div className="w-100">
        <div className="root bejelentkezes">
          <header>
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex justify-content-center"></div>
              <div></div>
            </div>
          </header>
          <main className="container-fluid d-flex justify-content-center align-items-center">
            <form
              className="col-12 col-sm ms-0 ms-sm-5 py-5"
              onSubmit={handleSubmit}
            >
              <div className="loginbox bg-white border rounded py-2 px-4">
                <div className="d-flex flex-column">
                  <div className="avatar d-flex justify-content-center py-3"></div>
                  <div className="d-flex flex-column labels text-center">
                    <div className="pb-1">
                      <h4>Üdvözlünk a Türki-S-Tárban,</h4>
                      <p>
                        ...a török sorozatok videó-Tárában!&nbsp; Amennyiben még
                        nem rendelkezel&nbsp; nálunk felhasználói fiókkal, úgy
                        kérjük,&nbsp; először{" "}
                        <a href="https://tst-toroksorozatok.com/" className="signupLabel">
                          regisztrálj
                        </a>
                        !
                      </p>
                    </div>
                    <div>
                    <LoginModal
                      component={LoginForm}
                    />
                    </div>
                    <div className="py-3">
                      <label>Bejelentkezés, mint:</label>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <label>Email-cím:</label>
                    <input
                      type="text"
                      className="inputs"
                      defaultValue=""
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-column py-3">
                    <label>Jelszó:</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="inputs"
                      defaultValue=""
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button onClick={togglePassword} className="eyePass"></button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="form-control text-light mt-2" type="submit">
                      Bejelentkezés
                    </button>
                  </div>
                  <div className="d-flex justify-content-around py-2 mt-1 more">
                    <a href="https://api.tst-turkistar.com/password_reset">
                      Jelszó-emlékeztető
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </main>
        </div>
        <footer>
          <div className="bg-c-3 row g-0 px-3 px-lg-5 justify-content-between f-14 py-3 align-items-center">
            <div
              className="col-12 col-md-auto text-white py-1 ps-0 ps-lg-2 pb-3 pb-md-0"
              id="footermenu"
            >
              <ul>
                <li>
                  <a
                    href="/files/adatvedelmi_nyilatkozat.pdf"
                    target={"_blank"}
                  >
                    Adatvédelmi nyilatkozat
                  </a>
                </li>
                <li>
                  <a
                    href="/files/felhasznalasi_feltetelek.pdf"
                    target={"_blank"}
                  >
                    Felhasználási feltételek
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-auto pe-0 pe-lg-2 text-center">
              Copyright © 2022 TST - Türki-S-Tár Hungary
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
