import React, { Component } from "react";
import {axiosInstance as axios} from "../../Helper/AxiosInstance";
import ApiConfig from "../../config.json";

const BASE_URL = `${ApiConfig.api_url}/Likes`;
let LikeData = {
  user_id: 0,
  series_id: 0,
  movies_id: 0,
  likeType: null,
};

class LikeDislike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      like: false,
      dislike: false,
      recorded: false,
      disabled: false,
      allReaction: 0,
      likeCount: 0,
    };
  }

  setLikeParameters = (like) => {
    console.log("setLike: " + like);
    like ? (LikeData.likeType = true) : (LikeData.likeType = false);

    LikeData.user_id = parseInt(localStorage.getItem("user"));
    switch (this.props.type) {
      case "movie":
        LikeData.movies_id = this.props.id;
        break;
      case "series":
        LikeData.series_id = this.props.id;
        break;
      default:
        break;
    }
  };

  handleLike(e) {
    e.preventDefault();
    this.setState({
      dislike: false,
      like: true,
    });

    if (LikeData.likeType === null) {
      this.setLikeParameters(true);
      axios
        .post(BASE_URL + `/add/`, LikeData)
        .then((res) => console.log(res.status));
    } else {
      if (LikeData.likeType !== true) {
        this.setLikeParameters(this.state.like);
        switch (this.props.type) {
          case "movie":
            axios
              .put(
                BASE_URL +
                  `/update/movie/${
                    this.props.id
                  }?user_id=${localStorage.getItem("user")}`,
                `{"likeType": true}`
              )
              .then((r) => console.log(r.status));
            break;
          case "series":
            axios
              .put(
                BASE_URL +
                  `/update/series/${
                    this.props.id
                  }?user_id=${localStorage.getItem("user")}`,
                `{"likeType": true}`
              )
              .then((r) => console.log(r.status));
            break;
          default:
            break;
        }
      }
    }
  }

  handleDisLike(e) {
    e.preventDefault();
    this.setState({
      like: false,
      dislike: true,
    });

    if (LikeData.likeType === null) {
      this.setLikeParameters(this.state.like);

      axios
        .post(BASE_URL + `/add/`, LikeData)
        .then((res) => console.log(res.status));
    } else {
      if (LikeData.likeType !== false) {
        this.setLikeParameters(this.state.like);
        switch (this.props.type) {
          case "movie":
            axios
              .put(
                BASE_URL +
                  `/update/movie/${
                    this.props.id
                  }?user_id=${localStorage.getItem("user")}`,
                `{"likeType": false}`
              )
              .then((r) => console.log(r.status));
            break;
          case "series":
            axios
              .put(
                BASE_URL +
                  `/update/series/${
                    this.props.id
                  }?user_id=${localStorage.getItem("user")}`,
                `{"likeType": false}`
              )
              .then((r) => console.log(r.status));
            break;
          default:
            break;
        }
      }
    }
  }

  componentWillMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(this.state.like)
  }

  componentWillUnmount() {}

  async componentDidMount() {
    this.setState({
      like: false,
      dislike: false,
    });
    console.log("Ez a like did mount: " + LikeData.likeType);
    switch (this.props.type) {
      case "movie":
        await axios
          .get(
            BASE_URL +
              `/movie/${this.props.id}?user_id=${localStorage.getItem(
                "user"
              )}`
          )
          .then((res) => {
            if (res.data === true || res.data === false) {
              LikeData.likeType = res.data;
            } else {
              LikeData.likeType = null;
            }
          });
        break;
      case "series":
        await axios
          .get(
            BASE_URL +
              `/series/${this.props.id}?user_id=${localStorage.getItem(
                "user"
              )}`
          )
          .then((res) => {
            if (res.data === true || res.data === false) {
              LikeData.likeType = res.data;
            } else {
              LikeData.likeType = null;
            }
          });
        break;
      default:
        break;
    }
    console.log("Likedata: " + LikeData.likeType);
    switch (this.props.type) {
      case "movie":
        await axios
          .get(BASE_URL + `/movie/count/${this.props.id}`)
          .then((res) => {
            this.setState({
              allReaction: res.data,
            });
          });
        break;
      case "series":
        await axios
          .get(BASE_URL + `/series/count/${this.props.id}`)
          .then((res) => {
            this.setState({
              allReaction: res.data,
            });
          });
        break;
      default:
        break;
    }
    switch (LikeData.likeType) {
      case true:
        this.setState({
          like: true,
        });
        break;
      case false:
        this.setState({
          dislike: true,
        });
        break;
      default:
        this.setState({
          dislike: false,
          like: false,
        });
    }
  }

  render() {
    return (
      <div
        className="col-auto align-content-center"
        style={{ userSelect: "none" }}
      >
        <button
          className={this.state.like ? "likeButtonOn" : "likeButton"}
          onClick={(e) => this.handleLike(e)}
          disabled={this.state.disabled}
        ></button>
        <button
          className={this.state.dislike ? "dislikeButtonOn" : "dislikeButton"}
          onClick={(e) => this.handleDisLike(e)}
          disabled={this.state.disabled}
        ></button>

        <span>
          {Object.values(this.state.allReaction)[0] +
            Object.values(this.state.allReaction)[1]}
          -ból {Object.values(this.state.allReaction)[0]} felhasználó kedvelte
          ezt
        </span>
      </div>
    );
  }
}

LikeDislike.propTypes = {};

export default LikeDislike;
