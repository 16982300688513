import React, { Component } from "react";
import { Navigate } from "react-router-dom";

export let searchString;

class NavSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: this.context,
      redirect: false,
      redirected: false,
      showHome: true,
    };
  }

  componentWillMount() {
    this.setState({
      redirected: false,
      redirect: false,
    });
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (
      this.state.searchString === "" &&
      window.location.pathname === "/" &&
      this.state.showHome === true
    ) {
      this.setState({
        showHome: false,
      });
    }
  }

  onInputChange = (e) => {
    this.setState({
      searchString: e.target.value,
    });
    searchString = e.target.value;
    if (this.state.searchString === "") {
      this.setState({
        showHome: true,
      });
    }
    // console.log(searchString)
  };
  handleSearchWindow = () => {
    this.setState({
      redirected: false,
      redirect: false,
    });
    return <Navigate to="/kereses"></Navigate>;
  };

  //redirect means that page has to be redirected to search page, redirected means that the actual page is search, so it's redirected
  render() {
    const redirect = this.state.redirect;

    if (redirect) {
      return (
        <div className="search bg-c-6 f-20 py-2">
          {this.state.redirected ? null : this.handleSearchWindow()}

          <form
            id="keresoFrm"
            name="keresoFrm"
            method="get"
            action="/"
            className="form text-center px-1 d-flex align-items-center"
          >
            <input name="view" type="hidden" id="view" value="kereses" />
            <input name="" autoFocus className="searchbutton ms-2 f-0" />
            <input
              type="text"
              name="mit"
              id="mit"
              autoFocus
              className="field d-inline-block px-3"
              placeholder="Keresés..."
              value={this.state.searchString}
              onInput={(e) => this.setState({ searchString: e.target.value })}
            />
          </form>
        </div>
      );
    } else {
      return (
        <div className="search bg-c-6 f-20 py-2">
          {this.state.showHome &&
          this.state.searchString === "" &&
          window.location.pathname !== "/" ? (
            <Navigate to="/" replace={true}></Navigate>
          ) : null}

          <form
            id="keresoFrm"
            name="keresoFrm"
            method="get"
            action="/"
            className="form text-center px-1 d-flex align-items-center"
          >
            <input name="view" type="hidden" id="view" value="kereses" />
            <input name="" className="searchbutton ms-2 f-0" />
            <input
              type="text"
              name="mit"
              id="mit"
              autoFocus
              className="field d-inline-block px-3"
              placeholder="Keresés..."
              value={this.state.searchString}
              onInput={this.onInputChange}
              onChange={() => this.setState({ redirect: true })}
            />
          </form>
        </div>
      );
    }
  }
}

NavSearch.contextType = NavSearch;

NavSearch.propTypes = {};

export default NavSearch;
