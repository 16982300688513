import React, { useState } from "react";
import { Modal as BSModal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";

function LoginModal({component}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true)
  };

  const InnerChild = component;

  return (
    <>
      <p className="c-2 modalText" onClick={handleShow}>A legnépszerűbb sorozatainkat erre a szövegre kattintva tekintheted meg.</p>{" "}
      <BSModal className="add" size="xl" show={show} onHide={handleClose}>
        <div className="modal-content">
          <CloseButton
            onClick={handleClose}
            className="closeButton"
            variant="white"
          />
          <InnerChild close={handleClose} />
        </div>
      </BSModal>
    </>
  );
}

export default LoginModal;
