import axios from "axios";

const getToken = () => localStorage.getItem("access");

const getAuthorizationHeader = () => `Bearer ${getToken()}`;
const config = {
    headers: {
        "Content-Type": "application/json",
    }
};
const getRefreshToken = async () => await axios.post("https://api.tst-turkistar.com/api/token/refresh", JSON.stringify({
    refresh: localStorage.getItem("refresh"),
}), config).then(response => {
    if (response?.status === 200) {
        localStorage.setItem("access", response.data.access)
    } else {
        window.location.href = "/kijelentkezes";
    }
}).catch((reason) => {
    console.log(reason);
    window.location.href = "/kijelentkezes";
});
export const axiosInstance = axios.create({
    headers: {Authorization: getAuthorizationHeader()},
});
axiosInstance.interceptors.response.use(async (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await getRefreshToken();
            originalRequest.headers['Authorization'] =  `Bearer ${getToken()}`
            return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
    });


