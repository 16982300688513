import React from "react";
import {axiosInstance as axios} from "../../Helper/AxiosInstance";
import ApiConfig from "../../config.json";

function LoginForm() {
  const [series, setSeries] = React.useState([]);
  function getData() {
    axios
      .get(`${ApiConfig.api_url}/Series/home/`)
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }
  React.useEffect(() => {
    getData();
  }, []);

  if (!series) return null;

  return (
    <React.Fragment>
      <div className="modal-dialog modal-xl">
        <div className="modal-content bg-c-6 border-0">
          <div className="modal-header d-block p-0 border-0 mb-4"></div>
          <React.Fragment>
            <div className="title z-100 text-white pb-2 float-text2">
              <div className="f-48 z-10 position-relative">
                <h3>TOP 10 sorozatunk</h3>
              </div>
              <div className="z-10 position-relative">
                <h4>
                  Több mint: 50 sorozat, 1000 sorozatrész - hetente új részekkel
                  bővülő kínálat.
                </h4>
              </div>
            </div>
            <div className="modal-body px-3 px-lg-5 py-4">
              <div className="row px-0 px-xl-5">
                <div className="col-12 pb-4">
                  <div className="row justify-content-start text-white">
                    {series.map((serieses) => (
                      <React.Fragment>
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-12 mb-4 hirbox">
                          <a href="#" className="d-block">
                            <div className="row g-0 align-items-center modalHirbox">
                              <div className="col-auto pe-2 pict">
                                <img
                                  src={
                                    ApiConfig.media_url +
                                    serieses.image_vertical
                                  }
                                  onError={(e) => {
                                    e.target.src = "/art/logo.png";
                                    e.target.style = "object-fit: contain";
                                  }}
                                  alt="..."
                                ></img>
                              </div>
                              <div className="col">
                                <div className="f-20">{serieses.name}</div>
                                <div className="f-20 text-uppercase c-9">
                                  Évadok száma: {serieses.seasons}
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
}
export default LoginForm;
