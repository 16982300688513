import React, { useState } from "react";
import { Modal as BSModal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
function HomeModal({ id, component, name }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const InnerChild = component;

  return (
    <>
      <p onClick={handleShow}> {name}</p>{" "}
      <BSModal className="add" size="xl" show={show} onHide={handleClose}>
        <div className="modal-content">
          <CloseButton
            onClick={handleClose}
            className="closeButton"
            variant="white"
          />
          <InnerChild id={id} close={handleClose} />
        </div>
      </BSModal>
    </>
  );
}

export default HomeModal;
