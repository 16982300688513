import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import { Dropdown } from "react-bootstrap";
import { motion } from "framer-motion";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import ApiConfig from "../config.json";
import YourListForm from "../Components/YourList/YourListForm";
import HomeModal from "../Components/Helper/HomeModal";
import SeriesForm from "../Components/Series/SeriesForm";

const userId = localStorage.getItem("user");

const YourListURL = `${ApiConfig.api_url}/Series/personal/`;
const SeriesURL = `${ApiConfig.api_url}/Series/?is_series_archive=false`;
const NewEpisodeURL = `${ApiConfig.api_url}/Episode/desc`;
const SoonURL = `${ApiConfig.api_url}/Blog/?limit=4`;

//with this we can list 'Saját listád'
const YourLists = () => {
  const [yourlist, setYourList] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${YourListURL}${userId}`)
      .then((response) => {
        setYourList(response.data);
      })
      .catch((reason) => console.log(reason));
  }, []);
  if (!yourlist[0])
    return (
      <div>
        <h1 className="yourListText">
          Jelenleg ez a lista üres, még nem vettél fel egy sorozatot sem a
          kedvenceid közé!
        </h1>
      </div>
    );
  if (!yourlist) return null;
  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={20}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Navigation]}
        className="slider"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
          1500: {
            slidesPerView: 5,
          },
        }}
      >
        {yourlist.map((list) => (
          <SwiperSlide>
            <div className="swiper-slide">
              <a href="#" className="position-relative d-block">
                <div className="d-flex justify-content-center">
                  <img
                    src={ApiConfig.media_url + list.image_horizontal}
                    onError={(e) => {
                      e.target.src = "/art/logo.png";
                      e.target.style = "object-fit: contain";
                    }}
                    className="swiperListImg"
                    alt="..."
                  ></img>
                </div>
                <div className="float-text">
                  <div className="f-28 fw-500 lh-15 position-relative z-100 mt-3">
                    <HomeModal
                      id={list.id}
                      name={list.name}
                      season={list.seasons}
                      component={YourListForm}
                    />
                  </div>
                  <div className="text-uppercase c-9 position-relative z-100">
                    Évadok száma: {list.seasons}
                  </div>
                  <div className="text-uppercase c-9 position-relative z-100"></div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

//with this we can list 'Elérhető sorozatok'
const SeriesList = () => {
  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${SeriesURL}&limit=15`)
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }, []);
  if (!series) return null;
  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={20}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Navigation]}
        className="slider"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
          1500: {
            slidesPerView: 5,
          },
        }}
      >
        {series.map((serie) => (
          <SwiperSlide>
            <div className="swiper-slide">
              <a href="#" className="position-relative d-block">
                <div className="d-flex justify-content-center">
                  <img
                    src={ApiConfig.media_url + serie.image_horizontal}
                    onError={(e) => {
                      e.target.src = "/art/logo.png";
                      e.target.style = "object-fit: contain";
                    }}
                    className="swiperListImg"
                    alt="..."
                  ></img>
                </div>
                <div className="float-text">
                  <div className="f-28 fw-500 lh-15 position-relative z-100 mt-3">
                    <HomeModal
                      id={serie.id}
                      name={serie.name}
                      season={serie.seasons}
                      component={SeriesForm}
                    />
                  </div>
                  <div className="text-uppercase c-9 position-relative z-100">
                    Évadok száma: {serie.seasons}
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

//with this we can list 'Hamarosan'
const SoonList = () => {
  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${SoonURL}`)
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }, []);
  if (!series) return null;
  return (
    <>
      {series.map((serie) => (
        <div className="soonHome col-12 col-md-6 col-lg-4 col-xl-6 mb-4">
          <a href="#" className="position-relative d-block">
            <div className="pict d-flex justify-content-center">
              <img
                src={ApiConfig.media_url + serie.image}
                onError={(e) => {
                  e.target.src = "/art/logo.png";
                  e.target.style = "object-fit: contain";
                }}
                className="soonListImg"
                alt="..."
              ></img>
            </div>
            <div className="float-text">
              <div className="f-28 fw-500 lh-15 position-relative mt-3">
                {serie.name}
              </div>
              <div className="text-uppercase z-10 position-relative c-9 fw-400">
                {serie.description}
              </div>
            </div>
          </a>
        </div>
      ))}
    </>
  );
};

//with this we can list the first swiper
const MainSeriesList = () => {
  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${SeriesURL}`)
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }, []);
  if (!series) return null;
  return (
    <>
      <Swiper
        spaceBetween={20}
        loop={true}
        loopFillGroupWithBlank={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        initialSlide={2}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        start
        modules={[Autoplay, EffectCoverflow]}
        className="pt-4 mt-4"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
          1500: {
            slidesPerView: 4,
          },
        }}
      >
        {series.map((serie) => (
          <SwiperSlide className="d-md-block d-flex justify-content-center">
            <img
              src={ApiConfig.media_url + serie.image_horizontal}
              onError={(e) => {
                e.target.src = "/art/logo.png";
                e.target.style = "object-fit: contain";
              }}
              className="mainSeriesList"
              alt="..."
            ></img>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

//with this we can list 'Új epizódok'
const NewEpisodeList = () => {
  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${NewEpisodeURL}?limit=15`)
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }, []);
  if (!series) return null;
  return (
    <>
      <Swiper
        spaceBetween={20}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Navigation]}
        className="slider"
        initialSlide={4}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
          1500: {
            slidesPerView: 5,
          },
        }}
      >
        {series.map((serie) => (
          <SwiperSlide>
            <div className="swiper-slide">
              <a href="#" className="position-relative d-block">
                <div className="d-flex justify-content-center">
                  <img
                    src={ApiConfig.media_url + serie.series.image_horizontal}
                    onError={(e) => {
                      e.target.src = "/art/logo.png";
                      e.target.style = "object-fit: contain";
                    }}
                    className="swiperListImg"
                    alt="..."
                  ></img>
                </div>
                <div className="float-text">
                  <div className="f-28 fw-500 lh-15 position-relative z-100 mt-3">
                    {serie.series.name}{" "}
                    {/*need to see the api endpoint data to understand this*/}
                  </div>
                  <div className="text-uppercase c-9 position-relative z-100">
                    Évadok száma: {serie.series.seasons}
                  </div>
                  <div className="position-relative z-100">
                    <a
                      href="/lejatszo"
                      onClick={() => {
                        localStorage.setItem("episode_name", serie.name);
                        localStorage.setItem("episode_id", serie.id);
                        localStorage.setItem("episode_link", serie.video);
                        localStorage.setItem("series", serie.series.name);
                      }}
                      className="btn btn-info"
                      type="button"
                    >
                      {serie.series.seasons}. évad {serie.name}. rész
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

//with this we can list 'Hírek'
const NewsList = () => {
  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${NewEpisodeURL}?limit=8`)
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }, []);
  if (!series) return null;
  return (
    <>
      {series.map((serie) => (
        <div className="col-xl-6 col-lg-4 col-sm-6 col-12 mb-4 hirbox">
          <a href="#" className="d-block">
            <div className="row g-0 align-items-center">
              <div className="col-auto pe-2 pict">
                <img
                  src={ApiConfig.media_url + serie.series.image_vertical}
                  onError={(e) => {
                    e.target.src = "/art/logo.png";
                    e.target.style = "object-fit: contain";
                  }}
                  alt="..."
                ></img>
              </div>
              <div className="col">
                <div className="f-20">{serie.series.name}</div>
                <div className="f-20 c-9">
                  {serie.series.seasons}. évad, {serie.name}. epizód
                </div>

                <div className="row g-0 align-items-center mt-2">
                  <div className="col-auto me-2 mb-2">
                    <a
                      href="/lejatszo"
                      onClick={() => {
                        localStorage.setItem("episode_name", serie.name);
                        localStorage.setItem("episode_id", serie.id);
                        localStorage.setItem("episode_link", serie.video);
                        localStorage.setItem("series", serie.series.name);
                      }}
                      className="btn btn-empty text-uppercase"
                    >
                      Új epizód
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      ))}
    </>
  );
};
export default function Home() {
  return (
    <>
      <motion.div
        className="mainHome"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: "100%" }}
      >
        <MainSeriesList />

        <div className="col-12 f-32 c-5  m-4 mb-0 mtitle fw-400 pb-3 pt-4">
          <div>
            <img
              src="/art/favourite.svg"
              alt=""
              width="30"
              className="me-2 mb-2"
            ></img>
            <h2 className="d-inline-block">Új epizódok</h2>{" "}
          </div>
        </div>
        <NewEpisodeList />

        <div className="col-12 f-32 c-5  m-4 mb-0 mtitle fw-400 pb-3">
          <div>
            <img
              src="/art/heart.svg"
              alt=""
              width="30"
              className="me-2 mb-2"
            ></img>
            <h2 className="d-inline-block">Saját listád </h2>{" "}
            <Dropdown className="d-inline-block pb-2 align-middle">
              <Dropdown.Toggle
                className="toogle"
                data-toggle="collapse"
                href="#card-menu-2"
                role="button"
                aria-expanded="false"
              >
                <span className="lh-0 c-7 f-48">...</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="card-menu-dd">
                <div className="card-body-dd px-3 py-2">
                  <ul>
                    <li>
                      <Dropdown.Item className="dropdown-items" href="/listad">
                        Összes megtekintése
                      </Dropdown.Item>
                    </li>
                  </ul>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <YourLists />

        <div className="col-12 f-32 c-5  m-4 mb-0 mtitle fw-400 pb-3">
          <div>
            <img
              src="/art/cloud.svg"
              alt=""
              width="30"
              className="me-2 mb-2"
            ></img>
            <h2 className="d-inline-block">Elérhető sorozatok</h2>{" "}
            <Dropdown className="d-inline-block pb-2 align-middle">
              <Dropdown.Toggle
                className="toogle"
                data-toggle="collapse"
                href="#card-menu-2"
                role="button"
                aria-expanded="false"
              >
                <span className="lh-0 c-7 f-48">...</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="card-menu-dd">
                <div className="card-body-dd px-3 py-2">
                  <ul>
                    <li>
                      <Dropdown.Item
                        className="dropdown-items"
                        href="/sorozatok"
                      >
                        Összes megtekintése
                      </Dropdown.Item>
                    </li>
                  </ul>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <SeriesList />

        <div className="container-fluid">
          <div className="row px-2 justify-content-center">
            <div className="col-12 col-xl-6 beharangozo">
              <div className="row">
                <div className="col-12 f-32 c-5 pb-2 mtitle">
                  <div>
                  { /*<img
                      src="/art/megaphone.svg"
                      alt=""
                      width="30"
                      className="me-2"
                    ></img>
                  <h2 className="d-inline-block">Hamarosan</h2>{" "}*/}
                  </div>
                  <img
                    src="/art/soon_pict.jpg"
                  />
                </div>
                <SoonList />
              </div>
            </div>

            <div className="col-12 col-xl-6">
              <div className="row">
                <div className="col-12 f-32 c-5 pb-2 mtitle">
                  <div>
                    <img
                      src="/art/megaphone.svg"
                      alt=""
                      width="30"
                      className="me-2"
                    ></img>
                    <h2 className="d-inline-block">Hírek</h2>{" "}
                  </div>
                </div>
                <NewsList />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
