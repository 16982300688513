import { searchString } from "../Components/Helper/NavSearch";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import Modal from "../Components/Helper/Modal";
import SeriesForm from "../Components/Series/SeriesForm";
import FilmForm from "../Components/Films/FilmForm";
import ApiConfig from "../config.json";

export const NavContext = React.createContext("");

export default function Search(props) {
  let endpoints = [
    `${ApiConfig.api_url}/Movie/?search_string=${searchString}&limit=5`,
    `${ApiConfig.api_url}/Series/?is_series_archive=false&search_string=${searchString}&limit=5&category_id=0`,
    `${ApiConfig.api_url}/Series/?is_series_archive=true&search_string=${searchString}&limit=5&category_id=0`,
  ];
  const [requestMovie, setRequestMovie] = React.useState([]);
  const [requestSeries, setRequestSeries] = React.useState([]);
  function getData() {
    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then((data) => {
      setRequestMovie(data[0].data);
      setRequestSeries([...data[1].data, ...data[2].data]);
    });
  }
  useEffect(() => {
    const timeOutId = setTimeout(() => getData(), 200);
    return () => clearTimeout(timeOutId);
  }, [searchString]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {requestMovie.length !== 0 ? (
        <React.Fragment>
          <div className="mainSeries">
            <div className="col-12 f-32 c-5  m-4 mtitle fw-400 pt-3">
              <div>
                <img
                  src="/art/cinema.svg"
                  alt=""
                  width="30"
                  className="me-2"
                ></img>
                <h2 className="d-inline-block">Talált filmek</h2>
              </div>
            </div>
            <div className="col-12 pb-2">
              <div className="row m-4 justify-content-start text-white">
                <div className="col-12 f-16 c-5 mt-4 mtitle fw-400"></div>
                {requestMovie.map((movies) => (
                  <React.Fragment>
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4 hirbox">
                      <a href="#" className="d-block">
                        <div className="row g-0 align-items-center">
                          <div className="col-auto pe-2 pict">
                            <img
                              src={ApiConfig.media_url + movies.image_vertical}
                              onError={(e) => {
                                e.target.src = "/art/logo.png";
                                e.target.style = "object-fit: contain";
                              }}
                              alt="..."
                            ></img>
                          </div>
                          <div className="col">
                            <div className="f-20">
                              <span>{movies.name}</span>
                            </div>
                            <div className="row g-0 align-items-center mt-3">
                              <div className="col-auto me-2 mb-2">
                                <Modal id={movies.id} component={FilmForm} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </React.Fragment>
                ))}

                <div className="col-6"></div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="mainSeries">
            <div className="col-12 f-32 c-5  m-4 mtitle fw-400 pt-3">
              <div>
                <h2 className="d-inline-block">
                  Nem találtunk a keresésnek megfelelő filmet.
                </h2>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {requestSeries.length !== 0 ? (
        <React.Fragment>
          <div className="mainSeries">
            <div className="col-12 f-32 c-5  m-4 mtitle fw-400 pt-3">
              <div>
                <img
                  src="/art/cinema.svg"
                  alt=""
                  width="30"
                  className="me-2"
                ></img>
                <h2 className="d-inline-block">Talált sorozatok:</h2>
              </div>
            </div>
            <div className="col-12 pb-2">
              <div className="row m-4 justify-content-start text-white">
                <div className="col-12 f-16 c-5 mt-4 mtitle fw-400"></div>
                {requestSeries.map((series) => (
                  <React.Fragment>
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4 hirbox">
                      <a href="#" className="d-block">
                        <div className="row g-0 align-items-center">
                          <div className="col-auto pe-2 pict">
                            <img
                              src={ApiConfig.media_url + series.image_vertical}
                              onError={(e) => {
                                e.target.src = "/art/logo.png";
                                e.target.style = "object-fit: contain";
                              }}
                              alt="..."
                            ></img>
                          </div>
                          <div className="col">
                            <div className="f-20">
                              <span>{series.name}</span>
                            </div>
                            <div className="f-20 text-uppercase c-9">
                              Évadok száma: <span>{series.seasons}</span>
                            </div>
                            <div className="row g-0 align-items-center mt-3">
                              <div className="col-auto me-2 mb-2">
                                <Modal id={series.id} component={SeriesForm} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </React.Fragment>
                ))}

                <div className="col-6"></div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="mainSeries">
            <div className="col-12 f-32 c-5  m-4 mtitle fw-400 pt-3">
              <div>
                <h2 className="d-inline-block">
                  Nem találtunk a keresésnek megfelelő sorozatot.
                </h2>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </motion.div>
  );
}
