import React from "react";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import Modal from "../Components/Helper/Modal";
import CreateForm from "../Components/Films/FilmForm";
import { motion } from "framer-motion";
import ApiConfig from "../config.json";

export default function Films() {
  const [film, setFilms] = React.useState([]);

  function getData() {
    axios
      .get(`${ApiConfig.api_url}/Movie/`)
      .then((response) => {
        setFilms(response.data);
      })
      .catch((reason) => console.log(reason));
  }

  React.useEffect(() => {
    getData();
  }, []);

  if (!film) return null;
  return (
    <React.Fragment>
      <motion.div
        className="mainSeries"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: "100%" }}
      >
        <div className="mainSeries">
          <div className="col-12 f-32 c-5  m-4 mtitle fw-400 pt-3">
            <div>
              <img
                src="/art/cinema.svg"
                alt=""
                width="30"
                className="me-2"
              ></img>
              <h2 className="d-inline-block">Filmek listája</h2>
            </div>
          </div>
          <div className="col-12 pb-2">
            <div className="row my-4 mx-2 justify-content-start text-white">
              <div className="col-12 f-16 c-5 mt-4 mtitle fw-400"></div>
              {film.map((films) => (
                <React.Fragment>
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4 hirbox">
                    <a href="#" className="d-block">
                      <div className="row g-0 align-items-center">
                        <div className="col-auto pe-2 pict">
                          <img
                            src={ApiConfig.media_url + films.image_vertical}
                            onError={(e) => {
                              e.target.src = "/art/logo.png";
                              e.target.style = "object-fit: contain";
                            }}
                            alt="..."
                          ></img>
                        </div>
                        <div className="col">
                          <div className="f-20">
                            <span>{films.name}</span>
                          </div>
                          <div className="row g-0 align-items-center mt-3">
                            <div className="col-auto me-2 mb-2">
                              <Modal id={films.id} component={CreateForm} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </React.Fragment>
              ))}

              <div className="col-6"></div>
            </div>
          </div>
        </div>
      </motion.div>
    </React.Fragment>
  );
}
