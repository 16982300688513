import React from "react";
import {axiosInstance as axios} from "../../Helper/AxiosInstance";
import LikeDislike from "../Helper/LikeDislike";
import ApiConfig from "../../config.json";

function FilmForm(props) {
  let movie = props.id;
  const [film, setFilms] = React.useState([]);
  function getData() {
    axios
      .get(`${ApiConfig.api_url}/Movie/${movie}`)
      .then((response) => {
        setFilms(response.data);
      })
      .catch((reason) => console.log(reason));
  }
  React.useEffect(() => {
    getData();
  }, []);

  if (!film) return null;

  return (
    <React.Fragment>
      <form>
        <div className="modal-dialog modal-xl">
          <div className="modal-content bg-c-6 border-0">
            <React.Fragment>
              <div className="modal-header d-block p-0 border-0">
                <div className="position-relative bigpict">
                  <div className="position-absolute d-flex justify-content-center align-items-end h-100 w-100">
                    <div className="pict position-absolute w-100 h-100">
                      <img
                        className="modalPict"
                        src={ApiConfig.media_url + film.image_horizontal}
                        onError={(e) => {
                          e.target.src = "/art/logo.png";
                          e.target.style = "object-fit: contain";
                        }}
                        alt="..."
                      ></img>
                    </div>
                    <div className="title z-100 text-white pb-2 float-text2">
                      <div className="f-48 z-10 position-relative">
                        <h3>{film.name}</h3>
                      </div>
                      <div className="shadow"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body px-3 px-lg-5 py-4">
                <div className="row px-0 px-xl-5">
                  <div className="col-12 text-white pb-4">
                    <div className="row justify-content-between text-uppercase">
                      <div className="col-auto">
                        <a href="#ismerteto">Ismertető</a>
                      </div>
                      <div className="col-auto">
                        <a href="#video">Videó</a>
                      </div>
                      <LikeDislike id={movie} type={"movie"}></LikeDislike>
                    </div>
                  </div>
                  <div className="col-12 f-32 c-5 pb-2 mtitle fw-400 pb-3">
                    <div>
                      <img
                        src="/art/document.svg"
                        alt=""
                        width="30"
                        className="me-2"
                      />
                      <h2 className="d-inline-block" id="ismerteto">
                        Ismertető
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 c-4 mb-5">
                    <div>
                      <p>{film.description}</p>
                    </div>
                  </div>
                  <div className="col-12 f-32 c-5 pb-2 mtitle fw-400 pb-3">
                    <div>
                      <img
                        src="/art/cinema.svg"
                        alt=""
                        width="30"
                        className="me-2"
                      />
                      <h2 className="d-inline-block" id="video">
                        Videó
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 pb-4">
                    <div className="row justify-content-start text-white">
                      <div className="col-xl-6 col-lg-6 col-sm-12 col-12 mb-4 hirbox">
                        <a href="#" className="d-block">
                          <div className="row g-0 align-items-center">
                            <div className="col-auto pe-2 pict">
                              <img
                                src={ApiConfig.media_url + film.image_vertical}
                                onError={(e) => {
                                  e.target.src = "/art/logo.png";
                                  e.target.style = "object-fit: contain";
                                }}
                                alt="..."
                              ></img>
                            </div>
                            <div className="col">
                              <div className="f-20">{film.name}</div>
                              <div className="row g-0 align-items-center mt-3">
                                <div className="col-auto me-2 mb-2">
                                  <a
                                    href="/film-lejatszo"
                                    onClick={() => {
                                      localStorage.setItem("film", film.name);
                                      localStorage.setItem("video", film.video);
                                    }}
                                    className="btn btn-empty text-uppercase"
                                  >
                                    Megnézés
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
export default FilmForm;
