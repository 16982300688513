import React, {useState} from "react";
import Navbar from "./Components/Helper/Navbar";
import Footer from "./Components/Helper/Footer";
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import Login from "./Views/Login";
import Signup from "./Views/Signup";
import Subscription from "./Views/Subscription";
import AnimatedRoutes from "./Views/AnimatedRoutes";

function App() {
    const [token, setToken] = useState("");
    if (window.location.href.includes("/bejelentkezes")) {
        return <Login></Login>
    } else if (window.location.href.includes("/regisztracio")) {
        return <Signup></Signup>
    } else if (window.location.href.includes("/elofizetes")) {
        return <Subscription></Subscription>
    }
    if (!localStorage.getItem("access")) {
        return (
            <Login setToken={setToken}/>
        )
    }
    return (
        <>

            <Router>

                <header>
                    <Navbar/>

                </header>
                <main>

                    <AnimatedRoutes></AnimatedRoutes>
                </main>
                <Footer/>
            </Router>


        </>
    );
}

export default App;
