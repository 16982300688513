import React from "react";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import Swal from "sweetalert2";
import ApiConfig from "../config.json";

const userId = localStorage.getItem("user_id");
const sourceURL = `${ApiConfig.api_url}/User/`;

export default function Subscription() {
  function createPost(sub_id) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };
    axios
      .post(`${sourceURL}${userId}/update-subscription/${sub_id}`, config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Sikeres regisztráció! Továbbiakról e-mailben tájékoztatunk!",
        }).then(function (result) {
          window.location.href = "/bejelentkezes";
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Hiba!",
          text: "Hiba történt a regisztráció során! Próbálja újra!",
        });
      });
  }
  return (
    <>
      <div className="w-100">
        <div className="root elofizetes">
          <header>
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex justify-content-center"></div>
              <div></div>
            </div>
          </header>
          <main className="container-xxl d-flex justify-content-center align-items-center">
            <form className="col-12 py-5">
              <div className="subsbox bg-white border rounded py-2 px-4">
                <div className="d-flex flex-column">
                  <div className="row">
                    <div className="col-xxl-3 col-12">
                      <h5 className="py-3 px-xxl-3 px-0">
                        Türki-S-Tár regisztráció -{" "}
                        <span className="aTagLabel">2</span>/2
                      </h5>
                      <p>
                        Második lépésben választanod kell előfizetési
                        csomagjaink közül, valamint előre be kell fizetned a
                        választott csomag díját.
                      </p>
                      <div className="pt-2">
                        <p>
                          Az előfzetés kiválasztása után emailben megkapod
                          tőlünk az utalásra vonatkozó adatokat. Ezután neked
                          kell az elutalást kezdeményezned, amit megtehetsz a
                          bankod internetes felületén keresztül, vagy akár
                          személyesen is.
                        </p>
                      </div>
                      <div className="pt-2">
                        <p>
                          Az összeg beérkezése és lekönyvelése után 24 órán
                          belül aktiválódik a fiókod, ezt követően tudod majd
                          igénybe venni szolgáltatásunkat.
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl col-lg-3 col-sm-6 col-12 seperate1 p-3">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Havi csomag</h5>
                          <h5 className="card-title2 mb-2 text-muted pb-5">
                            1 hónap
                          </h5>
                          <h5 className="card-subtitle mb-2 text-muted pt-5">
                            Bruttó
                          </h5>
                          <h4 className="card-subtitle colored pb-5">
                            1500 Ft
                          </h4>
                          <div className="pt-5 d-flex justify-content-center">
                            <a onClick={() => createPost(1)}>
                              <p className="subsButton">Ezt választom</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-xxl col-lg-3 col-sm-6 col-12 seperate2 p-3">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Negyedéves csomag</h5>
                          <h5 className="card-title2 mb-2 text-muted pb-5">
                            3 hónap
                          </h5>
                          <h5 className="card-subtitle mb-2 text-muted pt-5">
                            Bruttó
                          </h5>
                          <h4 className="card-subtitle colored pb-5">
                            4000 Ft
                          </h4>
                          <div className="pt-5 d-flex justify-content-center">
                            <a onClick={() => createPost(2)}>
                              <p className="subsButton">Ezt választom</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-xxl col-lg-3 col-sm-6 col-12 p-3">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Féléves csomag</h5>
                          <h5 className="card-title2 mb-2 text-muted pb-5">
                            6 hónap
                          </h5>
                          <h5 className="card-subtitle mb-2 text-muted pt-5">
                            Bruttó
                          </h5>
                          <h4 className="card-subtitle colored pb-5">
                            8000 Ft
                          </h4>
                          <div className="pt-5 d-flex justify-content-center">
                            <a onClick={() => createPost(3)}>
                              <p className="subsButton">Ezt választom</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-xxl col-lg-3 col-sm-6 col-12 seperate3 p-3">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Éves csomag</h5>
                          <h5 className="card-title2 mb-2 text-muted pb-5">
                            12 hónap
                          </h5>
                          <h5 className="card-subtitle mb-2 text-muted pt-5">
                            Bruttó
                          </h5>
                          <h4 className="card-subtitle colored pb-5">
                            15 000 Ft
                          </h4>
                          <div className="pt-5 d-flex justify-content-center">
                            <a onClick={() => createPost(4)}>
                              <p className="subsButton">Ezt választom</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </main>
        </div>
        <footer>
          <div className="bg-c-3 row g-0 px-3 px-lg-5 justify-content-between f-14 py-3 align-items-center">
            <div
              className="col-12 col-md-auto text-white py-1 ps-0 ps-lg-2 pb-3 pb-md-0"
              id="footermenu"
            >
              <ul>
                <li>
                  <a
                    href="/files/adatvedelmi_nyilatkozat.pdf"
                    target={"_blank"}
                  >
                    Adatvédelmi nyilatkozat
                  </a>
                </li>
                <li>
                  <a
                    href="/files/felhasznalasi_feltetelek.pdf"
                    target={"_blank"}
                  >
                    Felhasználási feltételek
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-auto pe-0 pe-lg-2 text-center">
              Copyright © 2022 TST - Türki-S-Tár Hungary
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
