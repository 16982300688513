import React from "react";
import FooterModalSeries from "./FooterModalSeries";
import SeriesForm from "../Series/SeriesForm";
import FilmForm from "../Films/FilmForm";
export default function Footer() {
  return (
    <footer className="bg-c-1">
      <div className="row g-0 bg-white">
        <div className="col-4 bg-c-2 rad-eb h-30"></div>
        <div className="col-4"></div>
        <div className="col-4 bg-c-3 rad-st"></div>
      </div>
      <div className="row g-0 px-3 px-lg-5 px-xl-3 px-xxl-5 pt-5 pb-5">
        <div className="col-12 col-xl-4 mb-5 mb-xl-0">
          <div className="col-auto c-4 f-40 fw-400 title">
            <h3>TST</h3>
          </div>
          <div className="f-16 c-5 text-justify">
            <p>
              Kedves Előfizető! <br></br>
              Szeretettel Üdvözlünk oldalunkon! Köszönjük, hogy regisztráltál,
              és bizalmaddal megtisztelsz minket! Kívánunk Neked jó szórakozást,
              és kellemes időtöltést, kedvenc sorozataid, és színészeid
              társaságában! Ha, pedig úgy döntesz, hogy filmbéli élményeidet,
              meglátásaidat megosztanád velünk,és rendelkezel Facebook
              profillal, akkor szívesen várunk zárt, Türki-S-Tár csoportunkba
              egy szuper Társaságba, ahol az aktuális hírek, információk
              mellett, jó hangulatú beszélgetéseket folytathatsz, már meglévő
              Tagjainkkal! Ehhez, csak annyit kell tenned,hogy itt a weboldalon
              a KÖZÖSSÉG menüre kattintva ismerősnek jelölöd az ott megjelenő
              Facebook profilt, majd messenger üzenetben kéred a felvételed a
              zárt csoportba!
              <br></br>Kívánunk Mindenkinek jó Egészséget, és kellemes
              időtöltést nálunk! <br></br>Szeretettel:<br></br>
              T-S-T Team{" "}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 col-xl-3 text-center mb-5 mb-md-0">
          <div className="col-auto c-4 f-40 fw-400 title">
            <h3>Sorozatainkból</h3>
          </div>
          <div id="sorozataink">
            <ul>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={42}
                    name="Alparslan: A Nagyszeldzsuk birodalom"
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={62}
                    name="Ébredés: A Nagyszeldzsuk birodalom"
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={40}
                    name="Az Oszmán birodalom alapítása"
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={49}
                    name="Jöjjön az élet, ahogyan tud..."
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={46}
                    name="Halld meg a hangomat"
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={31}
                    name="Mindent a házasságról"
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={29}
                    name="Apró gyilkosságok"
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={52}
                    name="A nevem Melek"
                    component={SeriesForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={55}
                    name="3 kurus"
                    component={SeriesForm}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-4 col-xl-3 text-center mb-5 mb-md-0">
          <div className="col-auto c-4 f-40 fw-400 title">
            <h3>Filmjeinkből</h3>
          </div>
          <div id="hamarosan" className="c-5">
            <ul>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={13}
                    name="Jönnek a törökök: Az igazság kardja"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={15}
                    name="Szerelem van a hangodban"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={11}
                    name="Tomiris legendája"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={16}
                    name="Két fajta ember"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={14}
                    name="Apám hegedűje"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={12}
                    name="Kicsi Partner"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={10}
                    name="Papíréletek"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={9}
                    name="Farkasfalka"
                    component={FilmForm}
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <FooterModalSeries
                    id={8}
                    name="Labirintus"
                    component={FilmForm}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/*<div className="col-12 col-md-4 col-xl-2 text-center mb-5 mb-md-0">
          <div className="col-auto c-4 f-40 fw-400 title">
            <h3>Hamarosan!</h3>
          </div>
          <div id="hamarosan" className="c-5">
            <ul>
              <li>A sötétség markában</li>
              <li>Távol a jövő</li>
              <li>Csapda</li>
            </ul>
          </div>
        </div>*/}
      </div>
      <div className="bg-c-3 row g-0 px-3 px-lg-5 justify-content-between f-14 py-3 align-items-center">
        <div
          className="col-12 col-md-auto text-white py-1 ps-0 ps-lg-2 pb-3 pb-md-0"
          id="footermenu"
        >
          <ul>
            <li>
              <a href="/files/adatvedelmi_nyilatkozat.pdf" target={"_blank"}>
                Adatvédelmi nyilatkozat
              </a>
            </li>
            <li>
              <a href="/files/felhasznalasi_feltetelek.pdf" target={"_blank"}>
                Felhasználási feltételek
              </a>
            </li>
            <li>
              <a href="/files/Hasznos_infok.pdf" target={"_blank"}>
                Hasznos információk
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-auto pe-0 pe-lg-2 text-center">
          Copyright © 2022 TST - Türki-S-Tár Hungary
        </div>
      </div>
    </footer>
  );
}
