import React from "react";
import {axiosInstance as axios} from "../../Helper/AxiosInstance";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.min.js";
import moment from "moment";
import NavSearch from "./NavSearch";
import ApiConfig from "../../config.json";

const userId = localStorage.getItem("user");
const sourceURL = `${ApiConfig.api_url}/User/`;

export default function Navbar() {
  let location = useLocation();
  const [user, setUser] = React.useState([]);
  function getData() {
    axios
      .get(`${sourceURL}${userId}`)
      .then((response) => {
        setUser(response.data[0]);
      })
      .catch((reason) => console.log(reason));
  }
  React.useEffect(() => {
    getData();
  }, []);

  //calculates how many days the subscription will expire
  const dateConverter = (timeEnd, startDate) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    let result = moment(newStartDate).diff(newEndDate, "days");
    return result;
  };

  //get current date
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  if (!user) return null;
  return (
    <>
      <header>
        <div className="navbarcolor">
          <div className="container-fluid pt-2 pt-sm-0">
            <div className="row align-items-center text-white justify-content-between">
              <div className="col-auto text-uppercase d-flex align-items-center logo order-1">
                <a href="/">
                  <img src="/art/logo.png" alt="logo"></img>
                </a>
                <p>
                  <strong>
                    Türki<br></br>s<br></br>
                    tár
                  </strong>
                </p>
              </div>

              <div className="col-12 col-xxl order-4 order-xxl-3">
                <nav className="navbar navbar-expand-xxl navbar-light font-20 pb-0">
                  <div
                    className="collapse navbar-collapse justify-content-around pb-3"
                    id="hidemenu"
                  >
                    <div
                      className="text-center text-uppercase f-18"
                      id="mainmenu"
                    >
                      <ul className="navbar-nav">
                        <Link to="/">
                          <li className="nav-item">
                            <a href="/" className="nav-link">
                              Kezdőlap
                            </a>
                          </li>
                        </Link>
                        <Link to="/sorozatok">
                          <li className="nav-item">
                            <a href="/#" className="nav-link">
                              Sorozatok
                            </a>
                          </li>
                        </Link>
                        <Link to="/filmek">
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Filmek
                            </a>
                          </li>
                        </Link>
                        <Link to="/archivum">
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Archívum
                            </a>
                          </li>
                        </Link>
                        <Link to="/kozosseg">
                          <li className="nav-item">
                            <a
                              href="https://www.facebook.com/tst.turkistar"
                              className="nav-link"
                              onClick={"https://www.facebook.com/tst.turkistar"}
                              target="_blank"
                            >
                              Közösség
                            </a>
                          </li>
                        </Link>
                      </ul>
                    </div>
                    <NavSearch></NavSearch>
                  </div>
                  <div
                    className="collapse position-fixed profile bg-c-3 text-uppercase"
                    id="profile"
                  >
                    <div className="py-3">
                      <div className="px-3 text-white text-end mb-2">
                        <a href="/profiladatok">
                          Profiladataid{" "}
                          <img
                            src="/art/user.svg"
                            alt=""
                            width="28"
                            className="ms-3"
                          ></img>
                        </a>
                      </div>
                      <div className="px-3 text-white text-end mb-2">
                        <a href="/listad">
                          Kedvenc sorozataid listája{" "}
                          <img
                            src="/art/heart-light.svg"
                            alt=""
                            width="28"
                            className="ms-3"
                          ></img>
                        </a>
                      </div>
                      <div className="bg-c-13 mt-3 mb-4 py-3">
                        <div className="text-center c-7 text-uppercase">
                          Előfizetésed
                        </div>
                        <div className="row justify-content-between px-3">
                          <div className="col-auto c-7 text-start">
                            {user.subscription_start}
                          </div>
                          <div className="col-auto text-white text-center">
                            {dateConverter(date, user.subscription_end)} nap
                            <br></br>
                            múlva lejár
                          </div>
                          <div className="col-auto c-7 text-end">
                            {user.subscription_end}
                          </div>
                        </div>
                      </div>
                      <div className="px-3 text-white text-end mb-2">
                        <a href="/kijelentkezes">
                          Kijelentkezés{" "}
                          <img
                            src="/art/logout.svg"
                            alt=""
                            width="28"
                            className="ms-3"
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <div
                className="col-auto ms-auto d-flex justify-content-end profil align-self-stretch order-3 order-xxl-4"
                data-bs-toggle="collapse"
                data-bs-target="#profile"
                href="#profile"
                aria-expanded="false"
                aria-controls="profile"
              >
                <div className="text-end pe-2 text-uppercase f-14 align-self-center">
                  <div>Profil {user.user_number}</div>
                  <div className="c-7">Profiladatok</div>
                </div>
                <div className="align-self-center">
                  <img
                    src="/art/user.svg"
                    alt="felhasznalo"
                    className="profilePict"
                  ></img>
                </div>
              </div>
              <div className="col-auto order-3 d-xxl-none">
                <div className="col-auto navbar-dark d-xxl-none px-0">
                  <button
                    className="navbar-toggler mx-auto p-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#hidemenu"
                    aria-controls="hidemenu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
