import React from "react";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import Modal from "../Components/Helper/Modal";
import ArchiveForm from "../Components/Series/SeriesForm";
import { motion } from "framer-motion";
import ApiConfig from "../config.json";

const Serieses = ({ category }) => {
  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(
        `${ApiConfig.api_url}/Series/?is_series_archive=false&category_id=${category.id}`
      )
      .then((response) => {
        setSeries(response.data);
      })
      .catch((reason) => console.log(reason));
  }, []);

  if (!series) return null;
  return (
    <>
      {series.map((serieses) => (
        <React.Fragment>
          <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4 hirbox">
            <a href="#" className="d-block">
              <div className="row g-0 align-items-center">
                <div className="col-auto pe-2 pict">
                  <img
                    src={ApiConfig.media_url + serieses.image_vertical}
                    onError={(e) => {
                      e.target.src = "/art/logo.png";
                      e.target.style = "object-fit: contain";
                    }}
                    alt="..."
                  ></img>
                </div>
                <div className="col">
                  <div className="f-20">{serieses.name}</div>
                  <div className="f-20 text-uppercase c-9">
                    Évadok száma: {serieses.seasons}
                  </div>
                  <div className="row g-0 align-items-center mt-3">
                    <div className="col-auto me-2 mb-2">
                      <Modal id={serieses.id} component={ArchiveForm} />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
export default function Series() {
  const [category, setCategory] = React.useState([]);

  function getData() {
    axios
      .get(`${ApiConfig.api_url}/Category/`)
      .then((response) => {
        setCategory(response.data);
      })
      .catch((reason) => console.log(reason));
  }

  React.useEffect(() => {
    getData();
  }, []);

  if (!category) return null;
  return (
    <React.Fragment>
      <motion.div
        className="mainSeries"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: "100%" }}
      >
        <div className="col-12 f-32 c-5  m-4 mtitle fw-400 pt-3">
          <div>
            <img src="/art/cinema.svg" alt="" width="30" className="me-2"></img>
            <h2 className="d-inline-block">Sorozatok listája műfajonként</h2>
          </div>
        </div>
        <div className="col-12 pb-2">
          <div className="row mx-2 my-4 justify-content-start text-white">
            {category.map((categories) => (
              <React.Fragment>
                <div className="col-12 f-16 c-5 mt-4 mtitle fw-400">
                  <div>
                    <h4 className="d-inline-block">{categories.name}</h4>
                  </div>
                </div>
                <Serieses category={categories} />
              </React.Fragment>
            ))}
            <div className="col-6"></div>
          </div>
        </div>
      </motion.div>
    </React.Fragment>
  );
}
