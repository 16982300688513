import React, { useState } from "react";
import {axiosInstance as axios} from "../Helper/AxiosInstance";
import Swal from "sweetalert2";
import ApiConfig from "../config.json";

const userId = localStorage.getItem("user");
const sourceURL = `${ApiConfig.api_url}/User/`;

export default function Profile() {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };
  const [user, setUser] = React.useState([]);
  const [fields, setFields] = useState({
    id: userId,
    first_name: "",
    last_name: "",
    email: "",
    postal_code: "",
    city: "",
    street_name: "",
    house_number: "",
    floor_apartment: "",
  });
  function getData() {
    axios
      .get(`${sourceURL}${userId}`)
      .then((response) => {
        setUser(response.data[0]);
      })
      .catch((reason) => console.log(reason));
  }
  React.useEffect(() => {
    getData();
  }, []);

  function handleChange(e) {
    const value = e.target.value;
    setFields({
      ...fields,
      [e.target.name]: value,
    });
  }
  function createPost() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };
    axios
      .post(
        `${ApiConfig.api_url}/User/update/`,
        JSON.stringify({
          id: userId,
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: fields.email,
          postal_code: fields.postal_code,
          city: fields.city,
          street_name: fields.street_name,
          house_number: fields.house_number,
          floor_apartment: fields.floor_apartment,
        }),
        config
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Sikeresen módosítottad a profilodat!",
        }).then(function (result) {
          if (result.value) {
            window.location.reload(false);
          }
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Hiba!",
          text: "Hiba történt a profil módosítása során! Kérem próbálja újra!",
        });
      });
    axios.get(`${sourceURL}${userId}`);
  }
  return (
    <>
      <div className="mainSeries">
        <div className="col-12 f-32 c-5  m-4 mtitle fw-400">
          <div>
            <img
              src="/art/userDef.svg"
              alt=""
              width="30"
              className="userDef me-2"
            ></img>
            <h2 className="d-inline-block">Profiladataid</h2>
          </div>
        </div>
        <div className="container rounded profildata bg-white mt-5 mb-5">
          <div className="row">
            <div className="col-md-3 border-right mt-5">
              <div className="d-flex flex-column align-items-center text-center p-3 py-5 mt-5">
                <span className="text-black-50 labels mt-5">{user.email}</span>
                <span className="text-black-50 labels">
                  Profilszám:{" "}
                  <span className="subscription"> {user.user_number}</span>
                </span>
                <span> </span>
              </div>
            </div>
            <div className="col-md-5 border-right">
              <div className="p-3 py-5">
                <div className="row mt-2">
                  <div className="col-md-6">
                    <label className="labels">Vezetéknév</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      onChange={handleChange}
                      defaultValue={user.first_name}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label className="labels">Keresztnév</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      onChange={handleChange}
                      defaultValue={user.last_name}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label className="labels">Irányítószám</label>
                    <input
                      type="text"
                      className="form-control"
                      name="postal_code"
                      onChange={handleChange}
                      defaultValue={user.postal_code}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Település</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      onChange={handleChange}
                      defaultValue={user.city}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Utca</label>
                    <input
                      type="text"
                      className="form-control"
                      name="street_name"
                      onChange={handleChange}
                      defaultValue={user.street_name}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Házszám</label>
                    <input
                      type="text"
                      className="form-control"
                      name="house_number"
                      onChange={handleChange}
                      defaultValue={user.house_number}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Emelet, lakás</label>
                    <input
                      type="text"
                      className="form-control"
                      name="floor_apartment"
                      onChange={handleChange}
                      defaultValue={user.floor_apartment}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">E-mail cím</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      defaultValue={user.email}
                      style={{
                        pointerEvents: isActive ? "" : "none",
                      }}
                    ></input>
                  </div>
                </div>
                <div className="mt-4 text-center pb-2">
                  {/*there may be a prettier solution, but not better*/}
                  <button
                    className="btn btn-primary profile-buttonModify"
                    type="button"
                    style={{
                      visibility: isActive ? "hidden" : "visible",
                      position: isActive ? "absolute" : "relative",
                    }}
                    onClick={handleClick}
                  >
                    Profilom módosítása
                  </button>
                  <button
                    className="btn btn-primary profile-button"
                    type="button"
                    onClick={createPost}
                    style={{
                      visibility: isActive ? "visible" : "hidden",
                      position: isActive ? "relative" : "absolute",
                    }}
                  >
                    Profilom mentése
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 py-5">
                <h4>Előfizetés</h4>
                <div className="d-flex justify-content-between align-items-center experience">
                  <label className="labels">Státusza:</label>
                  <span className="border rounded px-3 p-1 add-experience">
                    <i className="fa fa-plus"></i>&nbsp;Aktív
                  </span>
                </div>
                <br></br>
                <div className="d-flex justify-content-between align-items-center experience">
                  <label className="labels">Kezdete:</label>
                  <span className="border rounded px-3 p-1 subscription">
                    &nbsp;{user.subscription_start}
                  </span>
                </div>
                <br></br>
                <div className="d-flex justify-content-between align-items-center experience">
                  <label className="labels">Érvényessége:</label>
                  <span className="border rounded px-3 p-1 subscription">
                    &nbsp;{user.subscription_end}
                  </span>
                </div>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
