import React from "react";
import {axiosInstance as axios} from "../../Helper/AxiosInstance";
import Swal from "sweetalert2";

import LikeDislike from "../Helper/LikeDislike";
import ApiConfig from "../../config.json";

const userId = localStorage.getItem("user");

const Episodes = (props) => {
  const [episode, setEpisode] = React.useState([]);
  const [seen, setSeen] = React.useState([]);
  function setMegnezve(id) {
    if (!seen.some((item) => item === id)) {
      console.log("beléptünk");
      axios
        .post(
          `${ApiConfig.api_url}/Episode/personal/add/${localStorage.getItem("user")}/${id}`
        )
        .then((res) => console.log(res.status));
    }
  }

  React.useEffect(() => {
    axios
      .get(
        `${ApiConfig.api_url}/Episode/personal/${localStorage.getItem(
          "user"
        )}`
      )
      .then((response) => {
        setSeen([...response.data]);
      });

    axios
      .get(`${ApiConfig.api_url}/Episode/?series_id=${props.id}`)
      .then((response) => {
        setEpisode(response.data);
      })
      .catch((reason) => console.log(reason));
    console.log(seen);
  }, [props]);

  if (!episode) return null;
  return (
    <>
      {episode.map((episodes) => (
        <React.Fragment>
          <div className="col-xl-6 col-lg-6 col-sm-12 col-12 mb-4 hirbox">
            <a href="#" className="d-block">
              <div className="row g-0 align-items-center">
                <div className="col-auto pe-2 pict">
                  <img
                    src={ApiConfig.media_url + props.series.image_vertical}
                    onError={(e) => {
                      e.target.src = "/art/logo.png";
                      e.target.style = "object-fit: contain";
                    }}
                    alt="..."
                  ></img>
                </div>
                <div className="col">
                  <div className="f-20">{props.series.name}</div>
                  <div className="f-20 text-uppercase c-9">
                    {episodes.name}. epizód
                  </div>
                  <div className="row g-0 align-items-center mt-3">
                    <div className="col-auto me-2 mb-2">
                      <a
                        href="/lejatszo"
                        onClick={() => {
                          localStorage.setItem("episode_name", episodes.name);
                          localStorage.setItem("episode_link", episodes.video);
                          localStorage.setItem("series", props.series.name);
                          setMegnezve(episodes.id);
                        }}
                        className="btn btn-empty text-uppercase"
                      >
                        Megnézés
                      </a>
                    </div>
                    {seen.some((v) => v === episodes.id) ? (
                      <div className="col-auto c-12 ms-1 text-uppercase mb-2">
                        Láttad <img src="/art/eye.svg" width="24" />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

function SeriesForm(props) {
  let series = props.id;
  const [archive, setArchives] = React.useState([]);

  function getData() {
    axios
      .get(`${ApiConfig.api_url}/Series/${series}`)
      .then((response) => {
        setArchives(response.data);
      })
      .catch((reason) => console.log(reason));
  }

  React.useEffect(() => {
    getData();
  }, []);

  function createPost(series_id) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };
    axios
      .post(`${ApiConfig.api_url}/Series/personal/add/${userId}/${series_id}`)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Sikeresen hozzáadtad a listádhoz!",
          });
        }
      })
      .catch(function () {
        Swal.fire({
          icon: "error",
          title: "Hiba!",
          text: "Ez a sorozat már szerepel a listádban, nem veheted fel többször!",
        });
      });
  }

  if (!archive) return null;
  return (
    <React.Fragment>
      <form>
        <div className="modal-dialog modal-xl">
          <div className="modal-content bg-c-6 border-0">
            <React.Fragment>
              <div className="modal-header d-block p-0 border-0">
                <div className="position-relative bigpict">
                  <div className="position-absolute d-flex justify-content-center align-items-end h-100 w-100">
                    <div className="pict position-absolute w-100 h-100">
                      <img
                        className="modalPict"
                        src={ApiConfig.media_url + archive.image_horizontal}
                        onError={(e) => {
                          e.target.src = "/art/logo.png";
                          e.target.style = "object-fit: contain";
                        }}
                        alt="..."
                      ></img>
                    </div>
                    <div className="title z-100 text-white pb-2 float-text2">
                      <div className="f-48 z-10 position-relative">
                        <h3>{archive.name}</h3>
                      </div>
                      <div className="text-uppercase z-10 position-relative">
                        Évadok száma: {archive.seasons}
                      </div>
                      <div className="text-uppercase z-10 position-relative">
                        Elérhető epizódok száma: {archive.episodes}
                      </div>
                      <div className="shadow"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body px-3 px-lg-5 py-4">
                <div className="row px-0 px-xl-5">
                  <div className="col-12 text-white pb-4">
                    <div className="row justify-content-between text-uppercase">
                      <div className="col-auto">
                        <a href="#ismerteto">Ismertető</a>
                      </div>
                      <div className="col-auto">
                        <a href="#elozetes">Előzetes</a>
                      </div>
                      <div className="col-auto">
                        <a href="#epizodok">Epizódok</a>
                      </div>
                      <LikeDislike id={series} type={"series"}></LikeDislike>
                      <div className="col-auto">
                        <button
                          className="btn btn-empty text-uppercase"
                          onClick={() => createPost(archive.id)}
                        >
                          Saját listához adás
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 f-32 c-5 pb-2 mtitle fw-400 pb-3">
                    <div>
                      <img
                        src="/art/document.svg"
                        alt=""
                        width="30"
                        className="me-2"
                      />
                      <h2 className="d-inline-block" id="ismerteto">
                        Ismertető
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 c-4 mb-5">
                    <div>
                      <p>{archive.description}</p>
                    </div>
                  </div>
                  <div className="col-12 f-32 c-5 pb-2 mtitle fw-400 pb-3">
                    <div>
                      <img
                        src="/art/cinema.svg"
                        alt=""
                        width="30"
                        className="me-2"
                      />
                      <h2 className="d-inline-block" id="elozetes">
                        Előzetes
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 pb-4">
                    <div className="row justify-content-start">
                      <div className="col-12 col-sm-10 col-lg-6 mb-4">
                        <div className="position-relative bigpict">
                          <a
                            href="/elozetes"
                            onClick={() => {
                              localStorage.setItem(
                                "trailer_link",
                                archive.trailer
                              );
                              localStorage.setItem("series", archive.name);
                            }}
                            className="position-absolute d-flex justify-content-center align-items-end h-100 w-100"
                          >
                            <div className="pict position-absolute w-100 h-100">
                              <img
                                className="modalPict"
                                src={
                                  ApiConfig.media_url + archive.image_horizontal
                                }
                                onError={(e) => {
                                  e.target.src = "/art/logo.png";
                                  e.target.style = "object-fit: contain";
                                }}
                                alt="..."
                              ></img>
                            </div>
                            <div className="title z-100 text-white pb-2 float-text2">
                              <div className="f-28 z-10 position-relative"></div>
                              <div className="text-uppercase z-10 position-relative c-9 fw-400"></div>
                              <div className="shadow"></div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 f-32 c-5 pb-2 mtitle fw-400 pb-3">
                    <div>
                      <img
                        src="/art/cinema.svg"
                        alt=""
                        width="30"
                        className="me-2"
                      />
                      <h2 className="d-inline-block" id="epizodok">
                        Epizódok
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 pb-4">
                    <div className="row justify-content-start text-white">
                      <Episodes id={series} series={archive} />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}

export default SeriesForm;
