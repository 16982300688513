import React from "react";
export default function TrailerPlayer() {
  const trailer_video = localStorage.getItem("trailer_link");
  const series = localStorage.getItem("series");
  return (
    <>
      <div className="mainSeries">
        <div className="text-center">
          <div className="title z-100 text-white pb-2 float-text">
            <div className="f-48 z-10 position-relative">
              <h1>{series} előzetes</h1>
            </div>
          </div>
        </div>
        <div className="row px-0 px-xl-5 py-4">
          <div className="col-12 c-4 mb-5">
            <div className="ratio ratio-21x9">
              <iframe
                src={trailer_video}
                frameborder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="pb-2"></div>
      </div>
    </>
  );
}
