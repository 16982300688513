import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./Home";
import Series from "./Series";
import Search from "./Search";
import Profile from "./Profile";
import Player from "../Components/Series/Player";
import Archive from "./Archive";
import Films from "./Films";
import YourList from "./YourList";
import Logout from "./Logout";
import TrailerPlayer from "../Components/Series/TrailerPlayer";
import FilmPlayer from "../Components/Films/FilmPlayer";
import { AnimatePresence } from "framer-motion";
import Signup from "./Signup";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/sorozatok" element={<Series />}></Route>
        <Route path="/kereses" element={<Search />}></Route>
        <Route path="/profiladatok" element={<Profile />}></Route>
        <Route path="/lejatszo" element={<Player />}></Route>
        <Route path="/elozetes" element={<TrailerPlayer />}></Route>
        <Route path="/film-lejatszo" element={<FilmPlayer />}></Route>
        <Route path="/archivum" element={<Archive />}></Route>
        <Route path="/filmek" element={<Films />}></Route>
        <Route path="/listad" element={<YourList />}></Route>
        <Route path="/regisztracio" element={<Signup />}></Route>
        <Route path="/kijelentkezes" element={<Logout />}></Route>
      </Routes>
    </AnimatePresence>
  );
}
export default AnimatedRoutes;
